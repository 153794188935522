import { mapActions, mapGetters } from "vuex";
import { errorHandler } from '../../utils/helpers';

const UserMixin = {
  computed: mapGetters(['user']),
  methods: mapActions(['fetchSingleUser', "handleFetchCompany", "handleFetchCompanyProfile"]),
  async created() {
    const userSlug = localStorage.getItem("book_me-logged-in-user-slug");
    const userType = localStorage.getItem("book_me-logged-in-user-type");

    try {
      if(!this.user && userType && userType.toLowerCase() !== 'company') await this.fetchSingleUser(`${userSlug}`);
      if(!this.user && userType && userType.toLowerCase() === 'company') await this.handleFetchCompanyProfile(`${userSlug}`);

    } catch (error) {
      errorHandler(error, this.$toast);
    }
  }
};

export default UserMixin;
