export const destructureUser = user => {
  const currentUser = { ...user };
  const { profile: userProfile, token: userToken } = currentUser;
  delete user.profile;
  delete user.token;

  const profile = userProfile;
  const token = userToken.token;
  const about = user;

  return { profile, token, about };
};

export const errorHandler = (error, notifier) => {
  const { errors } = error;
  
  if(errors && errors.length > 0) {
    return notifier.error(`${errors[0]}`);
  }

  if (typeof error.message === "string") {
    return notifier.error(`${error.message}`);
  }
  const { error: { message }} = error;

  if (Array.isArray(message)) {
    const nextedErrorMsg = message[0][Object.keys(message[0])];
    return notifier.error(`${nextedErrorMsg}`);
  }
  
  if (typeof message !== "string" && Object.keys(message).length > 0) {
    const nextedErrorMsg = message[0][Object.keys(message[0])];
    return notifier.error(`${nextedErrorMsg}`);
  }
  return notifier.error(`${error.error.message}`);
}
