<template>
  <div>
    <topHeader :userData="user" />
    <div class="mentorship">
      <div class="mentorship__mentbgimg __workimg">
        <b-container>
          <p class="text-center mentorship__bookmen" v-if="sessionOwner && sessionOwner.fullName">Book {{ sessionOwner.fullName.split(' ')[0] }} for work.</p>
        </b-container>
      </div>

      <b-container>
        <b-row>
          <b-col cols="12" xl="1" lg="1" md="12" sm="1"></b-col>
          <b-col cols="12" xl="10" lg="10" md="12" sm="10">
            <div class="mentorship__cards">
              <b-row>
                <BookingSectionLeftBar @userToBook="setSessionOwner($event)" />
                <b-col cols="12" xl="7" lg="7" md="6" sm="6" v-if="bookingData">
                  <div class="mentorship__cards-card1">
                    <!-- <p class="mentorship__cards-mentp">Project Details</p> -->
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Full Name</label>
                      <!-- <input
                        type="text"
                        class="form-control form__formedit"
                        placeholder="Full Name"
                        v-model="bookingData.fullName"
                      /> -->
                    </div>
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Email</label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        placeholder="Email"
                        v-model="bookingData.email"
                      />
                    </div>
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Project Title</label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        placeholder="Project Title"
                        v-model="bookingData.title"
                      />
                    </div>
                    <div class="form-group">
                      <label for="" class="form__formlabel"
                        >Project Description</label
                      >
                      <textarea
                        class="form-control form__formedit"
                        rows="8"
                        v-model="bookingData.description"
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Project Type</label>
                      <select v-model="bookingData.type" class="form-control form__formedit">
                        <option value="full-time">Full time</option>
                        <option value="part-time">Part time</option>
                        <option value="contract">Contract</option>
                      </select>
                    </div>
                    <p class="form__formlabel mentorship__label">
                      Select project duration
                    </p>

                    <div class="calendar-container">
                      <v-date-picker
                        mode="date"
                        v-model="date"
                        is-range
                        @dayclick="setDay"
                      />
                    </div>
                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Amount</label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        placeholder="1000"
                        v-model="bookingData.amount"
                      />
                    </div>

                    <div class="form-group">
                      <label for="" class="form__formlabel mentorship__label">Phone</label>
                      <input
                        type="text"
                        class="form-control form__formedit"
                        placeholder="Phone number"
                        v-model="bookingData.phone"
                      />
                    </div>

                    <b-row>
                      <b-col class="col-xs-6" xl="7" lg="7" md="6" sm="6">
                        <button class="btn mentorship__btncancel" @click.prevent="resetForm">Clear Form</button>
                      </b-col>
                      <b-col
                        xl="5"
                        lg="5"
                        md="6"
                        sm="6"
                        class="professional__shifr col-xs-6"
                      >
                        <button class="btn mentorship__btnsave" @click="handleBookWork">Book Now <Loader :shouldShow="isBookingForWork" /></button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col cols="12" xl="1" lg="1" md="12" sm="1"></b-col>
        </b-row>
      </b-container>
    </div>
    <div class="__spacet7p"></div>
  </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from 'vuex';

import topHeader from "../components/topHeader";
import BookingSectionLeftBar from "./common/BookingSectionLeftBar";
import Loader from '../components/loader.vue';
import { errorHandler } from "../../utils/helpers";
import UserMixin from "../mixins/usersMixins";

export default {
  components: {
    topHeader,
    BookingSectionLeftBar,
    Loader
  },
  mixins: [UserMixin],
  data() {
    return initialState()
  },
  methods: {
    ...mapActions(['bookForWork']),
    setDay(date) {
      if(this.startDate && this.endDate) {
        this.startDate = undefined;
        this.endDate = undefined;
      }
      this.startDate ? this.endDate = date.id : this.startDate = date.id;

      if(this.startDate.split('-') > this.endDate.split('-')) {
        this.duration = `${this.endDate} - ${this.startDate}`;
      } else {
        this.duration = `${this.startDate} - ${this.endDate}`;
      }

    },
    setSessionOwner(value) {
      this.sessionOwner = { ...value };
    },
    async handleBookWork() {
      try {
        this.isBookingForWork= true;
        const { userSlug } = this.$route.params;
        const {
          fullName,
          email,
          title,
          description,
          type,
          amount,
          phone
        } = this.bookingData;
  
        const result = await this.bookForWork({
          fullName,
          email,
          title,
          description,
          type,
          amount,
          phone,
          duration: this.duration,
          slug: userSlug
        });
  
        localStorage.setItem('user_profile_url', `${userSlug}`);
        this.$toast.success(`${result.message}`);
        location.href = `${result.data.payment.config.authorization_url}`;
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.isBookingForWork = false;
      }
    },
    resetForm() {
      Object.assign(this.$data, initialState());
    }
  },
  mounted() {
    $('button[type="button"]').click(function () {
      $('button[type="button"].colorbg').removeClass("colorbg");
      $(this).addClass("colorbg");
    });
  },
  created() {
    this.bookingData.fullName = this.user.fullName;
    this.bookingData.email = this.user.email;
  },
};

function initialState () {
  return {
    value: "",
    isBookingForWork: false,
    userData: null,
    selectedRange: [],
    startDate: undefined,
    endDate: undefined,
    duration: undefined,
    data: undefined,
    bookingData: {},
    sessionOwner: {}
  };
}
</script>
