<template>
  <div class="loader-container" v-if="shouldShow">
    <svg
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.0"
      width="24px"
      height="24px"
      viewBox="0 0 128 128"
      xml:space="preserve"
    >
      <g>
        <circle cx="16" cy="64" r="16" :fill="color" />
        <circle
          cx="16"
          cy="64"
          r="14.344"
          :fill="color"
          transform="rotate(45 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="12.531"
          :fill="color"
          transform="rotate(90 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="10.75"
          :fill="color"
          transform="rotate(135 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="10.063"
          :fill="color"
          transform="rotate(180 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="8.063"
          :fill="color"
          transform="rotate(225 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="6.438"
          :fill="color"
          transform="rotate(270 64 64)"
        />
        <circle
          cx="16"
          cy="64"
          r="5.375"
          :fill="color"
          transform="rotate(315 64 64)"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64"
          calcMode="discrete"
          dur="720ms"
          repeatCount="indefinite"
        ></animateTransform>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    color: {
      type: String,
      default: "#ffffff"
    },
    shouldShow: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.loader-container {
  display: inline-block;
  margin-left: 5px;
}
</style>
