<template>
  <b-col cols="12" xl="5" lg="5" md="6" sm="6">
    <div class="mentorship__cards-card1">
      
      <hr v-if="userData && userData.profile" />
      <p class="mentorship__cards-pricecuren" v-if="userData && userData.profile && userData.profile.work_preferences">
        <img src="../../assets/img/briefcase.png" />&nbsp;&nbsp;&nbsp;
        <span>Work Preferences:</span>&nbsp;&nbsp;&nbsp;
        <template v-if="userData">
          {{ userData.profile.work_preferences }}
        </template>
      </p>
      <p class="mentorship__cards-pricecuren" v-if="userData && userData.profile && userData.profile.work_rate">
        <img src="../../assets/img/currency.png" />&nbsp;&nbsp;&nbsp;
        <span>Charges:</span>&nbsp;&nbsp;&nbsp;
        <template v-if="userData">
          ₦{{ `${userData.profile.work_rate}` }} (per hour)
        </template>
      </p>
      <hr />

      <p class="mentorship__cards-service">Select a service</p>

      <div class="radio radio-primary" v-if="!showBookWorkButton">
        <input
          type="radio"
          name="radio1"
          id="radio1"
          value="option1"
          @click.prevent="gotoBookMentorship"
          :checked="checkMentorship"
        />
        <label for="radio1">
          <p class="__radiop">Book for Mentorship</p>
        </label>
      </div>

      <div class="radio radio-primary" v-if="showBookWorkButton">
        <input
          type="radio"
          name="radio1"
          id="radio2"
          value="option2"
          :checked="checkWork"
          @click.prevent="gotoBookWork"
        />
        <label for="radio2">
          <p class="__radiop">Book for work</p>
        </label>
      </div>
      <div class="__spacet7p"></div>
      <hr />
      <p class="mentorship__cards-service">How payment is made</p>
      <p class="mentorship__cards-stor">
        There are are more than 80 Expert Networks specializing in everything
        from buy-side research to taxes and .There are are more than 80 Expert
        Networks specializing in everything from buy-side research to taxes and
        pharmaceuticals.
      </p>
    </div>
  </b-col>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "BookingSectionLeftBar",
  computed: mapGetters(["selectedMentorshipSession"]),
  data() {
    return {
      checkMentorship: false,
      checkWork: false,
      userData: null,
      slug: "",
      showBookWorkButton: false
    };
  },
  methods: {
    ...mapActions(["fetchSingleUserProfile", "handleFetchCompany", "fetchASession"]),
    gotoBookWork() {
      this.$router.push(`/${this.slug}/book-work`);
      this.checkWork = true;
      this.checkMentorship = false;
    },
    gotoBookMentorship() {
      this.$router.push(`/${this.slug}/book-mentorship/${localStorage.getItem("book_me-mentorshipSession")}`);
      this.checkWork = false;
      this.checkMentorship = true;
    }
  },
  mounted() {
    this.$route.fullPath.includes("work")
      ? (this.checkWork = true)
      : (this.checkMentorship = true);
  },
  async created() {
    this.showBookWorkButton = this.$route.path.includes('book-work');
    const { userSlug, sessionSlug } = this.$route.params;
    const { type: userType } = this.$route.query;
    this.slug = userSlug;
    try {
      let sessionOwner,
          data;
      

      if(sessionSlug) {
        await this.fetchASession({ userSlug, sessionSlug });
        localStorage.setItem("book_me-mentorshipSession", `${this.selectedMentorshipSession.slug}`);
      }

      if(userType === 'user') {
        data = await this.fetchSingleUserProfile(`${userSlug}`);
        data = data.data;
      }
      if(userType === 'company') data = await this.handleFetchCompany(`${userSlug}`);

      sessionOwner = data;

     this.$emit("userToBook", data); 
      this.userData = sessionOwner;
    } catch (error) {
      errorHandler(error, this.$toast);
    }
  }
};
</script>
